import { IconContext } from "react-icons";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
  SiJavascript,
  SiPython,
  SiNodedotjs,
  SiExpress,
  SiReact,
  SiMongodb,
  SiVisualstudiocode,
  SiGit,
  SiBootstrap,
  SiDjango,
  SiPostgresql,
  SiJest,
  SiMocha,
  SiChai,
  SiPostman,
  SiJupyter,
  SiFigma,
  SiR,
  SiTensorflow,
  SiSass,
  SiTypescript,
} from "react-icons/si";

export default function SkillsList({ title, skills }) {

  const renderTooltip = (props, skill) => (
    <Tooltip {...props}>
      {skill}
    </Tooltip>
  );

  return (
    <div className="skills-list">
      <h3>{title}</h3>
      <IconContext.Provider value={{ className: "react-icons m-4", size: '3em' }}>
        {skills.map((skill) => {
          const Icon = (() => {
            switch (skill) {
              case "JavaScript":
                return SiJavascript;
              case "Python":
                return SiPython;
              case "NodeJS":
                return SiNodedotjs;
              case "Express":
                return SiExpress;
              case "React":
                return SiReact;
              case "MongoDB":
                return SiMongodb;
              case "Visual Studio Code":
                return SiVisualstudiocode;
              case "Git":
                return SiGit;
              case "Bootstrap":
                return SiBootstrap;
              case "Django":
                return SiDjango;
              case "PostgreSQL":
                return SiPostgresql;
              case "Jest":
                return SiJest;
              case "Mocha":
                return SiMocha;
              case "Chai":
                return SiChai;
              case "Postman":
                return SiPostman;
              case "Jupyter":
                return SiJupyter;
              case "Figma":
                return SiFigma;
              case "R":
                return SiR;
              case "TensorFlow":
                return SiTensorflow;
              case "Sass":
                return SiSass;
              case "TypeScript":
                return SiTypescript;
              default:
                return null;
            }
            
          })();

          if (!Icon) return null;  // Return early if there's no matching icon

          return (
            <OverlayTrigger
              key={skill}
              placement="top"
              delay={{ show: 1, hide: 1 }}
              overlay={(props) => renderTooltip(props, skill)}
            >
              <span className="skill-icon-container">
                <Icon className="skill-icon" />
              </span>
            </OverlayTrigger>
          );
        })}
      </IconContext.Provider>
    </div>
  );
}
