import SkillsList from './SkillsList'
import { useRef, useEffect } from 'react'

export default function Project({link, name, description, skills, screenshot, background, readme}) {
    const screenshotRef = useRef(null);
    const infoboxRef = useRef(null);
    const screenshotContainerRef = useRef(null)

    const adjustInfoboxHeight = () => {
        if (screenshotRef.current && infoboxRef.current) {
            const screenshotHeight = screenshotRef.current.offsetHeight;
            if (infoboxRef.current.offsetHeight < screenshotHeight) {
                infoboxRef.current.style.height = `${screenshotHeight}px`;
            }
        }
    };

    useEffect(() => {
        const handleImageLoad = () => {
            adjustInfoboxHeight();
        };
    
        if (screenshotRef.current) {
            screenshotRef.current.addEventListener('load', handleImageLoad);
        }
    
        adjustInfoboxHeight();  // Run once initially, though might not have any effect if the image hasn't loaded yet
    
        // Add event listener for window resize
        window.addEventListener('resize', adjustInfoboxHeight);
    
        // Clean up the event listeners when the component is unmounted
        return () => {
            if (screenshotRef.current) {
                screenshotRef.current.removeEventListener('load', handleImageLoad);
            }
            window.removeEventListener('resize', adjustInfoboxHeight);
        };
    }, []);    
    
    return (
        <div className="section portfolio-section d-flex flex-column justify-content-start" style={background}>
            <div className="translucent-bg">
                <h1 className="text-center mt-3">Projects</h1>
                <div className="d-flex justify-content-center align-items-start project gap-5">
                    <div className="d-flex flex-column justify-content-center project-infobox" ref={infoboxRef}>
                        <h2 className="d-flex align-self-start project-name"><a href={link} target="_blank" rel="noreferrer">{name}</a></h2>
                        <p className='project-description text'>{description} <span><a href={readme} target="_blank" rel="noreferrer">README</a></span></p>
                        <SkillsList title="Key skills" skills={skills} />   
                    </div>
                    <div className="screenshot-container" ref={screenshotContainerRef}>
                        <img src={screenshot} alt="" className="screenshot" ref={screenshotRef}/>
                    </div>

                </div>
            </div>
        </div>
    );
}
