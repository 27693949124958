import './App.css';
import { Routes, Route } from 'react-router-dom'
import NavBar from '../../components/NavBar'
import HomePage from '../../components/HomePage'

export default function App() {
  return (
    <main className="App d-flex flex-column align-items-center">
      <>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage className="d-flex flex-column" />}/>
        </Routes> 
      </> 
    </main>
  );
}
