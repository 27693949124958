import { TransitionGroup, CSSTransition } from "react-transition-group";
import About from "./About";
import Skills from "./Skills";
import Project from "./Project";
import Experience from "./Experience";
import Contact from "./Contact";
import commodusScreenshot from "../assets/commodus.png"
import rightspotScreenshot from "../assets/rightspot.png"
import vibecheckScreenshot from "../assets/vibecheck.png"
import battleshipScreenshot from "../assets/battleship.png"
import commodusBackground from "../assets/commodus_bg2.png"
import rightspotBackground from "../assets/rightspot_bg.png"
import vibecheckBackground from "../assets/vibecheck_bg.png"
import battleshipBackground from "../assets/battleship_bg2.png"


export default function HomePage() {
    const commodusSkills = ['React', 'Express', 'NodeJS', 'MongoDB', 'Python']
    const rightSpotSkills = ['Django', 'PostgreSQL', 'Python']
    const vibecheckSkills = ['Express', 'NodeJS', 'MongoDB']
    const battleshipSkills = ['JavaScript']
    
    
    const commodusDescription = 'A single-page React app providing automated analytics of commodity prices. Implemented advanced statistical analysis with a focus on seamless UX. Node and Express were used for the server and MongoDB was used as the database. Analysis was done using a Python child process within Node.'
    const rightSpotDescription = 'Worked in a group of two to build a web app providing aggregated location statistics by using third-party APIs together with a bespoke PostgreSQL database. Back-end built using Python (Django). Took ownership of most of the back-end infrastructure, as well as constructing the final dataset using Pandas.'
    const vibecheckDescription = 'Web app for creating and sharing moodboards with moveable elements - text, images and links. Back-end built using NodeJS (Express), with a MongoDB database.'
    const battleshipDescription = 'Built a Battleship browser game using vanilla JavaScript, HTML and CSS. Created three levels of difficulty, with the hardest using a probabilistic algorithm for cell targeting.'

    const commodusReadme = 'https://github.com/andy-ag/Commodus'
    const rightspotReadme = 'https://github.com/andy-ag/RightSpot'
    const vibecheckReadme = 'https://github.com/andy-ag/Vibecheck'
    const battleshipReadme = 'https://github.com/andy-ag/Battleship'
    
    const battleshipBackgroundStyle = {
        backgroundImage: `url(${battleshipBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100%',
    }
    
    const vibecheckBackgroundStyle = {
        backgroundImage: `url(${vibecheckBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100%',
    }
    
    const rightspotBackgroundStyle = {
        backgroundImage: `url(${rightspotBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100%',
    }
    
    const commodusBackgroundStyle = {
        backgroundImage: `url(${commodusBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100%',
    }

    return(
        <div className="scroll-container">
          <TransitionGroup>
              <CSSTransition key="about" timeout={200} classNames="fade">
                  <section id="about">
                      <About />
                  </section>
              </CSSTransition>
              <CSSTransition key="skills" timeout={200} classNames="fade">
                  <section id="skills">
                      <Skills />
                  </section>
              </CSSTransition>
              <CSSTransition key="projects" timeout={200} classNames="fade">
                  <section id="projects">
                      <Project name={'Commodus'} link={'https://commodus-0019e73e3c2a.herokuapp.com/'} description={commodusDescription} skills={commodusSkills} screenshot={commodusScreenshot} background={commodusBackgroundStyle} readme={commodusReadme}/>
                      <Project name={'RightSpot'} link={'https://rightspot.fly.dev/'} description={rightSpotDescription} skills={rightSpotSkills} screenshot={rightspotScreenshot} background={rightspotBackgroundStyle} readme={rightspotReadme}/>
                      <Project name={'Vibecheck'} link={'https://vibecheck.fly.dev/'} description={vibecheckDescription} skills={vibecheckSkills} screenshot={vibecheckScreenshot} background={vibecheckBackgroundStyle} readme={vibecheckReadme}/>
                      <Project name={'Battleship'} link={'https://andy-ag.github.io/Battleship/'} description={battleshipDescription} skills={battleshipSkills} screenshot={battleshipScreenshot} background={battleshipBackgroundStyle} readme={battleshipReadme}/>
                  </section>
              </CSSTransition>
              <CSSTransition key="experience" timeout={200} classNames="fade">
                  <section id="experience">
                      <Experience />
                  </section>
              </CSSTransition>
              <CSSTransition key="contact" timeout={200} classNames="fade">
                  <section id="contact">
                      <Contact />
                  </section>
              </CSSTransition>
          </TransitionGroup>
        </div>
    )
}
