import backgroundImage from "../assets/experience.png";

export default function Experience() {
    const sectionStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100%',
    }
    
    return (
       <div className="section portfolio-section d-flex flex-column" style={sectionStyle}>
        <div className="translucent-bg">
        <h1 className="text-center text mt-3">Experience</h1>
        <div className="d-flex justify-content-center p-5 text experience">
            <div className="d-flex flex-column justify-content-start white-bg professional">
                <h2 className="mb-3">Professional</h2>
                <h3 className="mb-3">Full-time</h3>
                <div>
                    <h4>EY - Tax - Global Compliance & Reporting</h4>
                    <p>Managed cross-team, multi-country tax return preparation & submission workflows, worked on regulated tax audits. Majority of client portfolio comprised FTSE 100 companies</p>
                </div>
                <h3 className="mt-5 mb-3">Internships</h3>
                <div>
                    <h4>Frontier Economics</h4>
                    <p>Worked on several projects, including developing operational model for a household name fast-food company, performing economic analysis for a report commissioned by the UK government.</p>
                </div>
                <div>
                    <h4>EY - Indirect Tax</h4>
                    <p>Assisted in managing European-wide workflow for supply-chain tax implication review project, Produced RAG analysis for final client deliverable for top 5 global pharmaceutical company.</p>
                </div>
            </div>
            <div className="d-flex flex-column justify-content-start white-bg academic">
                <h2 className="mb-3">Academic</h2>
                <div>
                    <h4>General Assembly</h4>
                    <p>Software Engineering Immersive - 12 weeks</p>
                    <p>Completed a 12-week full-stack software engineering bootcamp with daily standups, lectures, coding exercises and four one-week-long projects using industry-standard frameworks. The course also covered UX fundamentals, test-driven development, fundamentals of algorithms and data structures.</p>
                </div>
                <div>
                    <h4>City, University of London</h4>
                    <p>Ph. D - Computer Science - incomplete</p>
                    <p>Applied Machine Learning techniques to the area of music information retrieval. Conducted a deep literature review on state-of-the-art methodologies to inform research direction. Designed novel architecture for an audio source separation model.</p>
                </div>
                <div>
                    <h4>London School of Economics</h4>
                    <p>MSc - Statistics - Merit</p>
                    <p>The course focused on applied projects in Python, R, Java. Modules included: Machine Learning and Data Mining, Bayesian Machine Learning, Statistical Computing, Algorithms and Computation.</p>
                </div>
                <div>
                    <h4>University of Warwick</h4>
                    <p>BSc - Economics - First class</p>
                    <p>1st Class grades in Corporate Strategy, Statistical Techniques, Econometrics 1, Econometrics 2: Microeconometrics, Research in Applied Economics (final year project).</p>
                </div>
            </div>
        </div>
        </div>
        </div>
    );
}
