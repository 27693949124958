import backgroundImage from "../assets/about_blue.png";
import profileImage from "../assets/linkedin_photo.JPG";

export default function About() {
    const sectionStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100%',
    }
    
    return (
        <div className="section portfolio-section d-flex flex-column justify-content-start" style={sectionStyle}>
            <div className="translucent-bg">
            <h1 className="text-center mt-3 text">About me</h1>
            <h3 className="text-center mt-3 text">Andrey Ageenkov - Junior Software Engineer</h3>
            <div className="section d-flex justify-content-center align-items-center gap-5 about">
                <img src={profileImage} alt="Andrey Ageenkov" id="profile-image"/>
                <div className="d-flex flex-column align-items-center w-50 about-text white-bg">
                    <p>
                    Driven by an innate curiosity about the world and a hunger for constant learning, I am interested in technology and its latent transformative power ‐ one that humanity should strive to harness. I enrolled in a software engineering bootcamp to develop the requisite skills for building out the technological capabilities of the future. I am excited to bring my cross‐ disciplinary insights from the fields of economics, statistics and computer science to a software engineering role, with a particular interest in the financial sector.
                    </p>
                    <p>
                    In my free time, I enjoy keeping up with the latest developments in the world of technology, and their wider socio‐political implications, as well as gaining a deeper appreciation for the complexity of the world through wide reading, with some of my favourite authors being Gilles Deleuze, Michel Foucault and Jorge Luis Borges. I also like to stay fit, listen to good music and cook hearty meals, with a particular affinity for show‐stopping sandwiches and other comfort food from around the world.
                    </p>
                </div>
            </div>
        </div>
        </div>
    );
}
