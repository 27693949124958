export default function NavBar() {
  return (
      <nav className="navbar navbar-expand-sm">
        <div className="container-fluid d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <span className="navbar-brand mb-0 h1 mr-3">Andrey Ageenkov</span>
            <div className="navbar-nav">
              <a className="nav-link" href="#about">
                  About
              </a>
              <a className="nav-link" href="#skills">
                  Skills
              </a>
              <a className="nav-link" href="#projects">
                  Projects
              </a>
              <a className="nav-link" href="#experience">
                  Experience
              </a>
              <a className="nav-link" href="#contact">
                  Contact
              </a>
            </div>
          </div>
        </div>
      </nav>
  );
}
