import SkillsList from "./SkillsList";
import backgroundImage from "../assets/abstract.png";

export default function Skills() {
    const proficientSkills = ['JavaScript', 'Python', 'NodeJS', 'Express', 'React', 'MongoDB', 'Visual Studio Code', 'Git', 'Bootstrap', 'Django', 'PostgreSQL'];
    const comfortableSkills = ['Jest', 'Mocha', 'Chai', 'Postman', 'Zsh', 'Jupyter', 'Figma', 'Java', 'R', 'TensorFlow', 'PyTorch', 'Sass'];
    const learningSkills = ['TypeScript'];

    const sectionStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100%',
    };

    return (
        <div className="section portfolio-section d-flex flex-column justify-content-start" style={sectionStyle}>
            <div className="translucent-bg">
                <h1 className="text-center mt-3 text">Skills</h1>
                <div className="container d-flex flex-column justify-content-between skills-section mt-5">
                    <div className="row">
                        <div className="col">
                            <SkillsList title="Proficient" skills={proficientSkills} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <SkillsList title="Comfortable" skills={comfortableSkills} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <SkillsList title="Currently learning" skills={learningSkills} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
