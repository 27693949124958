import { useState } from 'react';
import { FaEnvelope, FaLinkedin, FaGithub, FaRegCopy } from "react-icons/fa";
import backgroundImage from "../assets/contact.png";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export default function Contact() {
    const [copySuccess, setCopySuccess] = useState(false);

    const sectionStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100%',
    };

    const ICON_SIZE = 120;

    // const copyEmailToClipboard = () => {
    //     const email = "andyageenkov@gmx.com";
    //     navigator.clipboard.writeText(email).then(() => {
    //         console.log('Email copied to clipboard!');
    //         setCopySuccess(true);
    //         setTimeout(() => setCopySuccess(false), 1000);
    //     }).catch(err => {
    //         console.error('Failed to copy email: ', err);
    //     });
    // };

    // const renderTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Copy e-mail address
    //     </Tooltip>
    // );

    return (
        <div className="section portfolio-section d-flex flex-column" style={sectionStyle}>
            <div className="translucent-bg">
                <h1 className="text-center mt-3 text">Contact me</h1>
                <div className="container">
                    <div className="row justify-content-center contact-icons">
                        <div className="col text-center">
                            <a href="mailto:andyageenkov@gmx.com" className="contact-icon">
                                <FaEnvelope size={ICON_SIZE} />
                            </a>
                            <p className="text mt-3">andyageenkov@gmx.com</p>
                            {/* <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 1, hide: 1 }}
                                overlay={renderTooltip}
                            >
                                <div className="contact-icon" onClick={copyEmailToClipboard}>
                                    <FaRegCopy size={ICON_SIZE / 3} />
                                </div>
                            </OverlayTrigger>
                            {copySuccess && <p className="text mt-5">E-mail copied to clipboard!</p>} */}
                        </div>

                        <div className="col text-center">
                            <a href="https://www.linkedin.com/in/andrey-ageenkov/" target="_blank" rel="noreferrer" className="contact-icon">
                                <FaLinkedin size={ICON_SIZE} />
                            </a>
                        </div>
                        <div className="col text-center">
                            <a href="https://github.com/andy-ag" target="_blank" rel="noreferrer" className="contact-icon">
                                <FaGithub size={ICON_SIZE} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
